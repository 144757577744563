* {
	box-sizing: border-box;
}

:root {
	--color-bg: #2C2C32;
	--color-active: #0f7dc6;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

header {
	background-color: var(--color-bg);
	padding: 2rem 0;
	text-align: center;
}

header>a {
	color: white;
	text-decoration: none;
	padding: 0 1rem;
}

header>a:hover {
	text-decoration: underline;
}

h1 {
	text-align: center;
	margin-top: 0;
}

.container {
	max-width: 900px;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0 auto;
}

main {
	min-height: calc(100vh - 85px - 22px);
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

main a {
	text-decoration: none;
	color: var(--color-bg);
}

main a:hover {
	text-decoration: underline;
}

.active {
	color: var(--color-active);
	cursor: default;
}

.formInputClass {
	margin: 5px 0px !important;
	height: 40px !important;
}

.formTextareaClass {
	height: 7em;
	margin-top: 20px !important;
}

.boxUrl {
	margin-top: 20px;
}

.boxDataTable {
	margin-top: 20px;
}

.boxCustom {
	border-radius: 5px !important;
    box-shadow: 0 0 5px 3px rgba(70,237,98,.2)!important;
}